import bgDark from "../../assets/bg-dark.png";
import bgYellow from "../../assets/bg-yellow.png";
import buy from "../../assets/buy.png";
import sell from "../../assets/sell.png";
import england from "../../assets/england.png";
import nigeria from "../../assets/nigeria.png";

const Features = () => {
  return (
    <div className="mt-32 mb-24 px-6 lg:px-32">
      <div className="lg:grid grid-cols-2 gap-20">
        <div className="my-14">
          <div
            style={{ backgroundImage: `url(${bgDark})` }}
            className="rounded-3xl py-20 w-full bg-cover bg-no-repeat"
          >
            <div>
              <img src={buy} alt="" className="w-[70%] mx-auto" />
            </div>
            <div className="px-7 lg:px-[50px] mt-10">
              <div className="w-10 rounded-full p-2 bg-white flex items-center justify-between font-semibold">
                <img src={england} alt="" className="w-6 h-6" />
              </div>
              <div className="mt-4">
                <p className="lg:text-lg text-white font-semibold lg:max-w-[450px]">
                  Discover SwapGo's peer-to-peer marketplace where you can
                  exchange your money in a safe, quick, and cheap way.
                </p>
              </div>
            </div>
          </div>
          <div>
            <h1 className="max-lg:mt-8 mt-5 text-3xl lg:text-[45px] text-secondary lg:leading-[52.83px] font-semibold">
              Make an <span className="text-primary">Offer</span>
            </h1>
          </div>
        </div>
        <div className="lg:mt-28">
          <div className="grid justify-items-end">
            <h1 className="text-3xl lg:text-[45px] text-secondary lg:leading-[52.83px] font-semibold mb-10">
              Make an <span className="text-primary">Ask</span>
            </h1>
          </div>
          <div
            style={{ backgroundImage: `url(${bgYellow})` }}
            className="py-14 bg-cover rounded-3xl lg:py-20 w-full mx-auto bg-no-repeat"
          >
            <div>
              <img src={sell} alt="" className="w-[70%] mx-auto" />
            </div>
            <div className="px-7 lg:px-[50px] mt-10">
              <div className="w-10 rounded-full p-2 bg-white flex items-center justify-between font-semibold">
                <img src={nigeria} alt="" className="w-6 h-6" />
              </div>
              <div className="mt-4">
                <p className="lg:text-lg text-secondary font-semibold lg:max-w-[450px]">
                  Discover SwapGo's peer-to-peer marketplace where you can
                  exchange your money in a safe, quick, and cheap way.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
