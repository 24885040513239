import { useState } from "react";
import arrow from "../../assets/arrow.png";

const accordionData = [
  {
    title: "What is SwapGo?",
    content:
      "SwapGo is a mobile App that allows migrants to securely swap their currencies using a peer-to-peer method. SwapGo is unique because, regardless of the side you are one, you have complete control over the exchange rates.",
  },
  {
    title: "Can I change my Email?",
    content:
      "Unfortunately, emails are permanant and cannot be changed on SwapGo.",
  },

  {
    title: "What Do I do if a transaction fails?",
    content:
      "If a transaction fails, you can contact our support center through the app or send us an email at support@swapgo.co.uk describing the transaction and attaching your transaction ID.",
  },
  {
    title: "What Is your cancellation Policy?",
    content:
      "SwapGo is a mobile App that allows migrants to securely swap their currencies using a peer-to-peer method. SwapGo is unique because, regardless of the side you are one, you have complete control over the exchange rates.",
  },
  {
    title: "What Is SwapGo beta?",
    content:
      "SwapGo is a mobile App that allows migrants to securely swap their currencies using a peer-to-peer method. SwapGo is unique because, regardless of the side you are one, you have complete control over the exchange rates.",
  },
  {
    title: "How fast Is SwapGo?",
    content:
      "Praesent sit amet enim hendrerit, hendrerit odio eu, bibendum risus.",
  },
];

function Accordion() {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const handleClick = (index: number) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div>
      {accordionData.map((item, index) => (
        <div key={index} className="border-b border-gray-200">
          <div
            className="flex justify-between items-center w-full px-4 py-3 hover:cursor-pointer"
            onClick={() => handleClick(index)}
          >
            <h1
              className={`font-medium lg:font-semibold text-lg lg:text-2xl capitalize ${
                activeIndex === index ? "text-secondary" : "text-secondary/50"
              }`}
            >
              {item.title}
            </h1>
            <span className="grid justify-items-end">
              <img
                src={arrow}
                alt=""
                className={`w-[10%] transition-transform transform ${
                  activeIndex === index ? "" : "rotate-180"
                }`}
              />
            </span>
          </div>
          {activeIndex === index && (
            <div className="px-5 py-3 ease-in transform duration-500">
              <p className="text-secondary font-medium h-auto bg-white">
                {item.content}
              </p>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default Accordion;
