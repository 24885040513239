import { gql } from "@apollo/client";

export const REFRESH_TOKEN = gql`
  mutation AuthRefreshJWT($refreshToken: String!) {
    authRefreshAccessToken(refreshToken: $refreshToken)
  }
`;
export const GET_SUMSUB_AUTH_TOKEN = gql`
  query SumsubAuthToken {
    sumsubAuthToken {
      token
      userId
    }
  }
`;
