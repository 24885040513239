import logo from "../../assets/logo.png";
const Footer = () => {
  return (
    <div className="pb-16 px-10 md:px-20 lg:px-32 mt-24 bg-background text-secondary py-16">
      <div className="lg:flex justify-between">
        <div className="max-lg:mb-8">
          <img src={logo} alt="" className="max-w-[190px]" />
        </div>
        <div className="flex justify-between lg:w-[33.33%] max-lg:mb-10 xl:pl-10">
          <div>
            <h1 className="font-semibold text-lg mb-2">Features</h1>
            <p className="font-medium /80 space-y-2">Online Marketplace</p>
          </div>
          <div>
            <h1 className="font-semibold text-lg mb-2">Company</h1>
            <div className="font-medium /80 space-y-2">
              <p>About</p>
              <p>FAQ</p>
              <p>Contact us</p>
            </div>
          </div>
        </div>
        <div className="flex justify-between lg:w-[33.33%] xl:px-16">
          <div>
            <h1 className="font-semibold text-lg mb-2">Legal</h1>
            <div className="font-medium /80 space-y-2">
              <p>Terms</p>
              <p>Privacy</p>
              <p>Security</p>
            </div>
          </div>
          <div>
            <h1 className="font-semibold text-lg mb-2">Social</h1>
            <div className="font-medium /80 space-y-2">
              <p>Facebook</p>
              <p>Twitter </p>
              <p>LinkedIn</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
