import React from "react";
import Marquee from "react-fast-marquee";
import circle from "../../assets/circle.png";

const MarqueeComponent: React.FC = () => {
  const count: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  return (
    <div className="max-h-96 -mt-28">
      <div className="bg-black rotate-1 skew-x-[-20deg] z-10">
        <Marquee className="bg-black" direction="right">
          {count.map((num) => {
            console.log(num);
            return (
              <div className="flex items-center space-x-5 mx-3">
                <h1 className="text-white text-2xl font-semibold">SwapGo</h1>
                <img src={circle} alt="" className="w-4 h-4" />
              </div>
            );
          })}
        </Marquee>
      </div>
      <div className="bg-black rotate-2 skew-x-[-20deg]">
        <Marquee className="bg-black">
          {count.map((num) => {
            console.log(num);
            return (
              <div className="flex items-center space-x-5 mx-3">
                <h1 className="text-white text-2xl font-semibold">SwapGo</h1>
                <img src={circle} alt="" className="w-4 h-4" />
              </div>
            );
          })}
        </Marquee>
      </div>
    </div>
  );
};

export default MarqueeComponent;
