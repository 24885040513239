import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Verification from "./pages/Verification";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Home />} path="/" />
        <Route
          element={<Verification />}
          path="/verification/:accessToken/:userEmail/:access/:refresh"
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
