const Contact = () => {
  return (
    <div className="mx-6 lg:mx-32">
      <div className="bg-[#E0CCEB]/50 border rounded-lg max-md:p-3 border-black">
        <h1 className="text-secondary font-semibold text-center text-3xl leading-[45px]">
          Still have questions? Get in touch below.
        </h1>
      </div>
      <div className="lg:px-16 mt-8">
        <h1 className="text-lg font-semibold mb-3">Leave us a message</h1>
        <form action="" className="space-y-4">
          <div>
            <label htmlFor="name" className="text-secondary/50 font-medium">
              Full name
            </label>
            <input
              type="text"
              required
              className="border my-2 border-dashed rounded-lg py-2 outline-none px-3 w-full border-secondary/20"
            />
          </div>
          <div>
            <label htmlFor="email" className="text-secondary/50 font-medium">
              Email Address
            </label>
            <input
              type="email"
              required
              className="border my-2 border-dashed rounded-lg py-2 outline-none px-3 w-full border-secondary/20"
            />
          </div>
          <div>
            <label htmlFor="message" className="text-secondary/50 font-medium">
              Your message
            </label>
            <textarea
              required
              name="message"
              id=""
              cols={30}
              rows={10}
              className="border my-2 border-dashed rounded-lg py-2 outline-none px-3 w-full border-secondary/20"
            ></textarea>
          </div>
          <div className="grid justify-items-end">
            <button className="">Submit Message</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
