import { useEffect } from "react";
import Navbar from "../components/app/Navbar";
import HeroSection from "../components/home/HeroSection";
import Marquee from "../components/home/Marquee";
import Features from "../components/home/Features";
import Objectives from "../components/home/Objectives";
import CTA from "../components/home/CTA";
import FAQ from "../components/home/FAQ";
import Contact from "../components/home/Contact";
import Footer from "../components/home/Footer";
import { useLocation } from "react-router-dom";

const Home = () => {
  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === "") {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]); // do this on route change
  return (
    <div>
      <div>
        <Navbar />
      </div>
      <div>
        <HeroSection />
      </div>
      <div>
        <Marquee />
      </div>
      <div id="features">
        <Features />
      </div>
      <div>
        <Objectives />
      </div>
      <div>
        <CTA />
      </div>
      <div id="faq">
        <FAQ />
      </div>
      <div id="contact">
        <Contact />
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Home;
