import React from "react";
import SumsubWebSdk from "@sumsub/websdk-react";
import { useParams } from "react-router-dom";
import { GET_SUMSUB_AUTH_TOKEN } from "../lib/queries";
import client from "../lib/client";

interface SumsubTokenResponse {
  sumsubAuthToken: {
    token: string;
    userId: string;
  };
}

export default function Verification() {
  const { accessToken, userEmail, access, refresh } = useParams();
  async function accessTokenExpirationHandler() {
    const { data: newToken } = await client.mutate<SumsubTokenResponse>({
      mutation: GET_SUMSUB_AUTH_TOKEN,
      context: { tokens: { accessToken: access, refreshToken: refresh } },
    });
    return newToken?.sumsubAuthToken.token;
  }

  const config = {
    lang: "en",
    email: userEmail,
  };
  const options = { addViewportTag: false, adaptIframeHeight: true };
  const messageHandler = (type: string, payload: any) => {
    console.log("onMessage", type, payload);
  };
  const errorHandler = (error: any) => {
    console.log("onError", error);
  };
  return (
    <div>
      <SumsubWebSdk
        accessToken={accessToken!}
        expirationHandler={accessTokenExpirationHandler}
        config={config}
        options={options}
        onMessage={messageHandler}
        onError={errorHandler}
      />
    </div>
  );
}
