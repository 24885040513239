import mission from "../../assets/mission.png";
import vision from "../../assets/vision.png";

const Objectives = () => {
  return (
    <div className="my-28 px-6 lg:px-32">
      <div className="lg:flex justify-between">
        <div className="lg:max-w-[50%] max-lg:mb-14">
          <div className="flex items-center space-x-4 my-4">
            <img src={mission} alt="" className="w-10 h-10" />
            <h1 className="text-secondary font-semibold text-2xl">
              Our Mission
            </h1>
          </div>

          <div>
            <p className="text-secondary/50 font-medium text-lg lg:max-w-[450px]">
              To foster financial accessibility for immigrants around the world,
              and empower them to improve their own lives - through a platform
              based on community, trust, and fairness.
            </p>
          </div>
        </div>
        <div>
          <div className="flex items-center space-x-4 my-4">
            <img src={vision} alt="" className="w-10 h-10" />
            <h1 className="text-secondary font-semibold text-2xl">
              Our Vision
            </h1>
          </div>

          <div>
            <p className="text-secondary/50 font-medium text-lg">
              To be the first choice of financial services for immigrants.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Objectives;
