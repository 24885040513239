import {
  ApolloClient,
  InMemoryCache,
  from,
  createHttpLink,
} from "@apollo/client";
import { jwtDecode } from "jwt-decode";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { REFRESH_TOKEN } from "./queries";

interface RefreshJWTResponse {
  authRefreshAccessToken: string;
}
const GQL_URL = process.env.REACT_APP_API_URL;

const authLink = setContext(
  async (_, { headers, needsAuth = true, tokens }) => {
    let _accessToken = tokens.accessToken;

    if (needsAuth !== false) {
      const decodedToken = jwtDecode(_accessToken!) as {
        id: string;
        exp: number;
        iat: number;
      };
      const expirationTime = decodedToken.exp;
      if (Date.now() >= expirationTime * 1000) {
        const { data } = await client.mutate<RefreshJWTResponse>({
          mutation: REFRESH_TOKEN,
          variables: {
            refreshToken: tokens.refreshToken,
          },
          context: {
            needsAuth: false,
          },
        });

        if (data) {
          _accessToken = data.authRefreshAccessToken;
        }
      }
    }
    return {
      headers: {
        ...headers,
        authorization:
          needsAuth === false
            ? ""
            : _accessToken
            ? `bearer ${_accessToken}`
            : "",
      },
    };
  }
);

const errorLink = onError((error) => {
  const { graphQLErrors, networkError } = error;
  if (graphQLErrors) {
    for (const { message, extensions } of graphQLErrors) {
      if (extensions?.code === "UNAUTHORIZED") {
        // refreshAccessToken();
        return;
      }
      console.log(
        `[GraphQL error]: Message: ${message}, Code: ${extensions?.code}`
      );
    }
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

const httpLink = createHttpLink({
  uri: GQL_URL,
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([authLink, errorLink, httpLink]),
});

export default client;
