import Accordion from "../app/Accordion";

const FAQ = () => {
  return (
    <div className="mx-6 lg:mx-32 py-14 lg:py-28">
      <div className="bg-[#E0CCEB]/50 max-md:p-3 border rounded-lg border-black">
        <h1 className="text-secondary font-semibold text-center text-3xl leading-[45px]">
          We’ve answered some of your questions, so you don’t have to ask.
        </h1>
      </div>
      <div className="my-14">
        <Accordion />
      </div>
    </div>
  );
};

export default FAQ;
