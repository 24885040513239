import cta from "../../assets/cta.png";
import apple from "../../assets/apple.png";
import google from "../../assets/google-play.png";
const CTA = () => {
  return (
    <div className="lg:px-32 px-6">
      <div className="rounded-xl bg-primary p-5 lg:p-10 lg:pr-0 py-10 lg:grid grid-cols-2 gap-14 relative overflow-hidden">
        <div>
          <div>
            <h1 className="text-white font-semibold text-3xl my-4">
              Peer-to-peer Marketplace
            </h1>
            <div className="text-white font-medium space-y-4">
              <ul className="">
                <p>
                  With SwapGo, set your preferred rate and then wait for someone
                  to match your order.
                </p>
              </ul>
              <ul>
                <p>
                  As exchange rates fluctuate, SwapGo let's you adjust your
                  orders, so you can get fair and competitive rates always.
                </p>
              </ul>
            </div>
          </div>
          <div className="mt-12 mb-10">
            <h1 className="text-white font-bold text-3xl lg:text-4xl max-w-[420px]">
              Soon available on your favourite app stores
            </h1>
            <div className="flex items-center space-x-5 mt-7">
              <div className="flex items-center justify-center text-white border space-x-3 rounded-lg bg-secondary px-4 py-3">
                <div className="w-5 sm:w-7">
                  <img className="w-full" alt="" src={apple} />
                </div>
                <div>
                  <p className="text-xs text-paragraph">Download on the</p>
                  <h1 className="max-sm:text-xs font-medium text-primaryBlack -mt-1">
                    App Store
                  </h1>
                </div>
              </div>
              <div className="flex items-center justify-center text-white border space-x-3 rounded-lg bg-secondary px-4 py-3">
                <div className="w-5 sm:w-7">
                  <img className="w-full" alt="" src={google} />
                </div>
                <div>
                  <p className="text-xs text-paragraph">Get it on</p>
                  <h1 className="max-sm:text-xs font-medium -mt-1">
                    Google Play
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="max-lg:hidden w-[60%] absolute right-0">
          <img src={cta} alt="" />
        </div>
      </div>
    </div>
  );
};

export default CTA;
