import { useState } from "react";
import logo from "../../assets/logo.png";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [showNav, setShowNav] = useState(false);
  return (
    <div className="">
      <div className="flex items-center justify-between lg:py-7 border-b border-primary/40 lg:mx-20 mx-6 py-4">
        <div className="lg:w-[33.33%]">
          <Link to="/">
            <img src={logo} alt="" className="w-[30%]" />
          </Link>
        </div>
        <div className="max-lg:hidden lg:w-[33.33%]">
          <div className="p-1 rounded-xl border border-primary bg-primary bg-opacity-20 text-primary w-fit mx-auto">
            <h1 className="quicksand font-semibold">Coming Soon</h1>
          </div>
        </div>
        <div className="max-lg:hidden lg:w-[33.33%] grid justify-items-end">
          <div className="flex items-center space-x-7 text-primary text-opacity-80">
            <Link to="/#features">
              <h1 className="quicksand">Features</h1>
            </Link>
            <Link to="/#faq">
              <h1 className="quicksand">FAQs</h1>
            </Link>
            <Link to="/#contact">
              <h1 className="quicksand">Contact Us</h1>
            </Link>
          </div>
        </div>
        <div
          className="lg:hidden grid justify-items-end"
          onClick={() => setShowNav(!showNav)}
        >
          {showNav ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5"
              />
            </svg>
          )}
        </div>
      </div>
      {showNav && (
        <div>
          <div className="absolute left-0 mt-2 w-full bg-white z-10 space-y-6 p-6 shadow-lg">
            <div className="space-y-4">
              <Link to="/#features">
                <h1 className="cursor-pointer hover:text-primaryBlue font-medium text-paragraph duration-500 text-lg">
                  Features
                </h1>
              </Link>
              <Link to="/#faq">
                <h1 className="cursor-pointer hover:text-primaryBlue font-medium text-paragraph duration-500 text-lg">
                  FAQs
                </h1>
              </Link>
              <Link to="/#contact">
                <h1 className="cursor-pointer hover:text-primaryBlue font-medium text-paragraph duration-500 text-lg">
                  Contact Us
                </h1>
              </Link>
              <div className="my-3">
                <button className="w-full font-semibold">Download App</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
